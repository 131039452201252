import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { path, match, propOr } from 'ramda'
import classnames from 'classnames'
import { format } from 'date-fns'

import { SubscriptionStore } from '../subscription-plan'
import styles from '../subscription-plan.css'
import { E2E_SELECTORS } from '../../../../../../e2e-selectors'
import { usePlanChangeFlag } from '../../../../../../hooks/usePlanChange'
import PriceChangeAlert from './price-change-alert'
import { usePriceChangeAlertFlag } from '../../../../../../hooks/usePriceChangeAlert'

const priceCharSplitRegExp = match(/(\d{1,})|(\.(\d{2}))/g)

const CurrentMonthPlan = () => {
  const {
    currentSubscription,
    activeVoucher,
    isSparkVoucher,
    upcomingSubscription
  } = useContext(SubscriptionStore)
  const getPlanDetails = usePlanChangeFlag()
  const showPriceChangeAlert = usePriceChangeAlertFlag()

  const promotion = propOr([], 'promotion', currentSubscription)
  const planStartsAt = path(['startsAt'])(upcomingSubscription)
  let planStartsAtFormatted = planStartsAt
  if (planStartsAt) {
    const [day, month, year] = planStartsAt.split('/')
    planStartsAtFormatted = format(
      new Date(year, month - 1, day),
      'd MMMM yyyy'
    )
  }
  const isFreeTrial = propOr(false, 'isFreeTrial', promotion)
  // Do not display promotional price for free trial promotions
  const promotionalPrice = !isFreeTrial && propOr('', 'price', promotion)
  const currentSubsriptionPrice = propOr('', 'price', currentSubscription)

  const [whole = '', decimal = ''] = priceCharSplitRegExp(
    promotionalPrice || currentSubsriptionPrice
  )
  const subscriptionName = currentSubscription.name === 'Premium' ? 'Standard' : currentSubscription.name
  const pricePeriod = subscriptionName === 'Annual' ? '/year' : '/month'
  const usuallyPrice = currentSubscription.price

  const getSubscriptionDisplayName = () => {
    const defaultSubscriptions = [getPlanDetails?.basic, getPlanDetails?.standard, getPlanDetails?.annual]
    const subscription = defaultSubscriptions.find(sub => subscriptionName === sub?.title)
    return subscription?.label
  }

  return (
    <div className={classnames(styles.container, styles.noBorder)}>
      <h2 className={styles.header}>Current Plan</h2>
      <div className={styles.planInfoContainer}>
        <div className={styles.planNameContainer}>
          <span className={styles.planName} data-lbx-e2e={subscriptionName}>
            {getSubscriptionDisplayName()}
          </span>
          {isSparkVoucher && (
            <span
              className={styles.planInfo}
              data-lbx-e2e={E2E_SELECTORS.SPARK_CUSTOMER}
            >
              Spark customer
            </span>
          )}
        </div>
        <div className={styles.planPriceContainer}>
          <span className={styles.currency}>$</span>
          <span className={styles.priceInt}>{whole}</span>
          <div className={styles.planDecimalWrapper}>
            <span className={styles.priceDecimal}>{decimal}</span>
            <span className={styles.pricePerMonth}>{pricePeriod}</span>
          </div>
        </div>
        {activeVoucher.length !== 0 &&
          Math.floor(usuallyPrice) !== parseFloat(whole) && (
          <span className={styles.priceComment}>
            Usually
            {' '}
            {`$${usuallyPrice}`}
          </span>
        )}
      </div>

      <ChangingSubscription
        planName={upcomingSubscription?.name}
        planStartsAtFormatted={planStartsAtFormatted}
      />

      { showPriceChangeAlert && <PriceChangeAlert /> }
    </div>
  )
}

const ChangingSubscriptionNoAnnualPlan = ({
  isDowngrading,
  nextPaymentDateParsed
}) => {
  return (
    isDowngrading && (
      <p
        className={styles.information}
        data-lbx-e2e={E2E_SELECTORS.START_NOTIFICATION}
      >
        Your Standard Plan will begin on
        {' '}
        {nextPaymentDateParsed}
      </p>
    )
  )
}

ChangingSubscriptionNoAnnualPlan.propTypes = {
  isDowngrading: PropTypes.bool.isRequired,
  nextPaymentDateParsed: PropTypes.string.isRequired
}

const ChangingSubscription = ({ planName, planStartsAtFormatted }) => {
  const { isUpgrading, isDowngrading } = useContext(SubscriptionStore)
  return (
    <>
      {isDowngrading && (
        <p className={styles.information}>
          Your
          {' '}
          {planName}
          {' '}
          Plan will start on
          {' '}
          {planStartsAtFormatted}
        </p>
      )}
      {isUpgrading && (
        <p className={styles.information}>
          Your
          {' '}
          {planName}
          {' '}
          Plan will start on
          {' '}
          {planStartsAtFormatted}
        </p>
      )}
    </>
  )
}

ChangingSubscription.propTypes = {
  planName: PropTypes.string,
  planStartsAtFormatted: PropTypes.string
}

export default CurrentMonthPlan
